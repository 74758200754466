import request from '@/utils/request'

//生产执行分析树
export function GetManufacturingTree(params) {
  return request({
    url: 'api/Equipment/GetManufacturingTree',
    method: 'get',
    params
  })
}
export function GetManufacturingTree2(params) {
  return request({
    url: 'api/Equipment/GetManufacturingTreeDefaultEquipmentId',
    method: 'get',
    params
  })
}
//生产执行分析
export function GetManufacturingPerformAnalysis(params) {
  return request({
    url: 'api/Equipment/GetManufacturingPerformAnalysis',
    method: 'get',
    params
  })
}
//设备综合效率-设备利用率/设备故障率/产能负荷率/设备生产效率
export function GetOEEAnalysis(params) {
  return request({
    url: 'api/Equipment/GetOEEAnalysis',
    method: 'get',
    params
  })
}
//设备故障分析-设备故障时间统计/设备故障次数前10/设备故障时长前10/设备故障类型分析/设备故障代码分析
export function GetEquipmentTroublesAnalysis(params) {
  return request({
    url: 'api/Equipment/GetEquipmentTroublesAnalysis',
    method: 'get',
    params
  })
}