<template>
	<div class="header">
		<!-- <span>{{title}}</span> -->
		<span>{{Dates}} {{time}}</span>
		
		<div class="goBack" v-if="btnName!=''" @click="GoBack()">
		  <img :src="back"/>
		  <span style="color: #38cb89!important;padding-left: 6px;">{{btnName}}</span>
		</div>
	</div>
</template>

<script>
  import {
    formatDate,getNowFormatDate
  } from '@/common/js/date.js'

  export default {
	  props: {
		title:{
		  type: [String],
		  default: ''
		},
		btnName:{
		  type: [String],
		  default: ''
		},
		BackUrl:{
		  type: [String],
		  default: ''
		},
	  },
    data() {
      return {
		Dates:formatDate(new Date(), 'yyyy-MM-dd'),
		countdown:null,
		interval:null,
		time:null,
		back:require('../../../static/images/goback.png'),
	  }
    },
    computed:{
		
    },
    created(){
      
    },
    watch: {
		
    },
	activated(){
		
	},
    mounted() {
		this.countdown= setInterval(() => {
		 this.time = getNowFormatDate().substring(11);
		}, 1000);
		
    },
    methods: {
		GoBack(){
			this.$router.push({
				path:this.BackUrl
			});
		}
    },
	beforeDestroy() {
	 clearInterval(this.countdown);
	 this.countdown = null;
	 this.time = null;
	},
  }
</script>

<style lang="scss" scoped>
@import '@/assets/mixin.scss';
@media only screen and (max-aspect-ratio: 1920/920 ) {
.header{
	position: relative;
	width: 100%;
	height: 30px;
	line-height: 0px;
}
// .header span:nth-child(1){
// 	font-size: 17px;
// 	font-weight: 600;
// 	@include font_color("font_color_white");
// }
.header span{
	font-size: 16px;
	@include font_color("font_color_white");
}
.goBack{
	position: absolute;
	width: 120px;
	top: -5px;
	right: 0;
	text-align: right;
}
.goBack img{
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	width: 16px;
	height: 16px;
}
.goBack span{
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
}
}
@media only screen and (min-aspect-ratio: 1920/920 ) {
.header{
	position: relative;
	width: 100%;
	height: 3vh;
	line-height: 0px;
}
.header span:nth-child(1){
	font-size: 17px;
	font-weight: 600;
	@include font_color("font_color_white");
}
.header span:nth-child(2){
	font-size: 15px;
	@include font_color("font3_color_white");
	padding-left:15px;
}
.goBack{
	position: absolute;
	width: 120px;
	top: -0.5vh;
	right: 0;
	text-align: right;
}
.goBack img{
	display: inline-block;
	vertical-align: middle;
	cursor: pointer;
	width: 16px;
	height: 1.6vh;
}
.goBack span{
	display: inline-block;
	font-size: 14px;
	font-weight: 600;
	cursor: pointer;
}
}
</style>