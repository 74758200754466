<template>
	<div class="StatusDetails">
		<!-- <headers :title="Header_title" :btnName="btnName" :BackUrl="BackUrl"></headers> -->
		<div class="gaugeoutfit2">
			<span class="xian"></span>
			<span class="title">{{groupTitle}}</span>
			<!-- <span class="mark" :style="{ 'color': colorStyle}" @click="tap(0)">设备工作状态<span class="mark2" :style="{ 'color': colorStyle}">|</span></span>
			<span class="mark" :style="{ 'color': colorStyle2}" @click="tap(1)">设备实时绩效<span :style="{ 'color': colorStyle2}" class="mark2">|</span></span>
			<span class="mark" :style="{ 'color': colorStyle3}" @click="tap(2)">用电状况监测</span> -->
			<!-- <div class="tabs" v-for="(item,index) in List" :key="index" @click="tap(index)" :class="activeIndex==index ? 'active' : ''">{{item.name}}</div> -->
		</div>
		<el-row :gutter="18" class="grid-content-main">
		   <!-- <el-col :span="4"> -->
		   <el-col :span="isTreeCell" class="grid-content-height">
			   <div class="grid-content_l"
				 	v-loading="loading"
				  element-loading-text="加载中"
				  element-loading-spinner="el-icon-loading"
				  element-loading-background="rgba(1,34,48, 0)">
				   <el-tree
					   :data="dataTree"
					    class="tree"
						:indent="0"
						node-key="Id"
					   :highlight-current = "true"
					   :current-node-key="current_node_key"
					    ref="tree"
					    default-expand-all
					   :props="defaultProps"
						 :check-strictly="true"
						:check-on-click-node="true"
						:expand-on-click-node="false"
					   @node-click="handleNodeClick">
					   <span slot-scope="{ node, data }" class="custom-tree-node">
					   <span v-if="BackgroundImg=='white'">
							<img v-if="!data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/branch_normal.png"/>
							<img v-if="!data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/branch_active.png"/>
							
							<img v-if="data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/workshopNormal.png"/>
							<img v-if="data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/workshopActive.png"/>
						</span>
						<span v-else>
							<img v-if="!data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/normal2.png"/>
							<img v-if="!data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/branch_active.png"/>
							
							<img v-if="data.Children && node.isCurrent==false" class="TreeImg" src="../../../static/images/workshopNormal2.png"/>
							<img v-if="data.Children && node.isCurrent==true" class="TreeImg" src="../../../static/images/workshopActive.png"/>
						</span>
						 <el-tooltip v-if="node.label.length>10" class="item" effect="dark" :content="node.label" placement="top-start">
						   <span style="margin-left: 10px">{{ node.label | ellipsis(10) }}</span>
						 </el-tooltip>
						 <span v-else style="margin-left: 10px">{{ node.label | ellipsis(10) }}</span>
						 <span v-if="data.disabled" class="disabled" @click.stop></span>
						</span>
				   </el-tree>
					 <div class="grid-content-open" v-if="isIconOpen" @click="handleTreeClick">
							<!-- <img v-if="!isIconOpen" class="grid-tree-icon" src="../../../static/images/close_icon.png" alt="" srcset="">
							<img v-else class="grid-tree-icon" src="../../../static/images/open_icon.png" alt="" srcset=""> -->
							<img class="grid-tree-icon" src="../../../static/images/close_icon.png" alt="" srcset="">
					 </div>
					 	<span v-show="emptyMsg" class="icon">
							<img :src="empty" class="icon-img">
							<div class="icon-text">暂无数据</div>
						</span>
			   </div>
		   </el-col>
		   <!-- <el-col :span="20"> -->
		   <el-col :span="isCoentCell" class="grid-content-height">
			   <div class="grid-content_r">
						<div class="grid-content-close" v-if="!isIconOpen" @click="handleTreeClick">
							<img class="grid-tree-icon" src="../../../static/images/open_icon.png" alt="" srcset="">
					 	</div>
				   <!-- <el-row>
				      <el-col :span="7">
				   	   <div class="monitor_1">
				   		 当前位置：<span>{{Name}}</span> > <span>{{Level}}</span><span v-if="NavName!=''"> > {{NavName}}</span>
				   	   </div>
				      </el-col>
				      <el-col :span="17">
				   	   <div class="monitor_r">
				   		  <div class="StateStyle">
							  <span class="title">所属事业部：</span>
							  <span class="num">{{AffiliatedUnit}}</span>
						  </div>
				   	   </div>
				      </el-col>
				   </el-row> -->
				   <!--内容区域-->
				   <el-row :gutter="18">
					   <el-col :span="5">
							<div class="Regional_l">
								<div class="RegionalImg" 
								v-loading="loading"
									element-loading-text="加载中"
										element-loading-spinner="el-icon-loading"
										element-loading-background="rgba(1,34,48, 0)" v-if="Bgurl!=''||Bgurl!=null">
									<el-image class="img" :src="Bgurl" :preview-src-list="srcList"></el-image>
									<span v-show="emptyMsg" class="icon">
										<img :src="empty" class="icon-img">
										<div class="icon-text">暂无数据</div>
									</span>
								</div>
								<!-- -1 停机 0 待机 3 运行 5报警 -->
								<div class="RegionalList">
									<span class="span1" v-if="Content.EquipmentStatus==0">
										设备状态：
										<span class="span2" style="color: #ff9600!important;">{{Content.EquipmentStatusName}}</span>
									</span>
									<span class="span1" v-if="Content.EquipmentStatus==3">
										设备状态：
										<span class="span2" style="color: #38cb89!important;">{{Content.EquipmentStatusName}}</span>
									</span>
									<span class="span1" v-if="Content.EquipmentStatus==5">
										设备状态：
										<span class="span2" style="color: #f14848!important;">{{Content.EquipmentStatusName}}</span>
									</span>
									<span class="span1" v-if="Content.EquipmentStatus==-1">
										设备状态：
										<span class="span2" style="color: #cccccc!important;">{{Content.EquipmentStatusName}}</span>
									</span>
								</div>
								<div class="RegionalList">
									<span class="span1">设备名称：<span class="span2">{{Content.EquipmentName}}</span></span>
								</div>
								<div class="RegionalList">
									<span class="span1">设备厂商：<span class="span2">{{Content.ModelName}}</span></span>
								</div>
								<div class="RegionalList">
									<span class="span1">设备型号：<span class="span2">{{Content.EquipmentTypeName}}</span></span>
								</div>
							</div>
					   </el-col>
					   <el-col :span="19">
							<div class="Regional_rBox Regional_r2">
								<div class="Regional_rItem1 Regional_rIndex1" :class="isActiveTab1 ? 'Regional_tabAct':'Regional_tabNor'" @click="tap(0)">
									<div class="Regional_rTitle">设备工作状态</div>
									<div class="Regional_rCont">
										<span class="Regional_rTxt mb15">主轴倍率(%): <span class="Regional_rNum">{{cnc_srate}}</span></span>
										<span class="Regional_rTxt">主轴负荷: <span class="Regional_rNum">{{cnc_rdspload}}</span></span>
									</div>
								</div>
								<div class="Regional_rItem2 Regional_rIndex2" :class="isActiveTab2 ? 'Regional_tabAct2':'Regional_tabNor2'" @click="tap(1)">
									<div class="Regional_rTitle">设备实时绩效</div>
									<div class="Regional_rCont">
										<span class="Regional_rTxt mb15">设备利用率(%): <span class="Regional_rNum">{{UtilizationRate}}</span></span>
										<span class="Regional_rTxt">设备稼动率(%): <span class="Regional_rNum">{{ActiveRate}}</span></span>
									</div>
								</div>
								<div class="Regional_rItem2 Regional_rIndex3" :class="isActiveTab3 ? 'Regional_tabAct2':'Regional_tabNor2'" @click="tap(2)">
									<div class="Regional_rTitle">用电状况监测</div>
									<div class="Regional_rCont">
										<span class="Regional_rTxt mb15">实时功率(kW): <span class="Regional_rNum">{{tmp1}}</span></span>
										<span class="Regional_rTxt">日用电量(kWh): <span class="Regional_rNum">{{tmp2}}</span></span>
									</div>
								</div>
							</div>
						   <!--设备工作状态 start-->
						   <div class="Regional_r" v-if="activeIndex===0">
							   <!-- <div class="Regional_r_t" v-loading="loading2"
						    element-loading-text="加载中"
					        element-loading-spinner="el-icon-loading"
					        element-loading-background="rgba(1,34,48, 0)">
							   	 <el-row :gutter="10">
							   	   <el-col :span="3">
							   	     <div class="gridContent">
							   	       <span>主轴倍率(%)</span>
							   	       <span>{{GetArrayValue('cnc_srate')}}</span>
									   <img src="../../../static/main/Separator.png" class="gridContentpic"/>
							   	     </div>
							   	   </el-col>
							   	   <el-col :span="4">
							   	     <div class="gridContent">
							   	       <span style="white-space: nowrap;">主轴转速(mm/min)</span>
							   	       <span>{{GetArrayValue('cnc_acts')}}</span>
									   <img src="../../../static/main/Separator.png" class="gridContentpic"/>
							   	     </div>
							   	   </el-col>
							   	   <el-col :span="3">
							   	     <div class="gridContent">
									   <span>主轴负荷</span>
							   	       <span>{{GetArrayValue('cnc_rdspload')}}</span>
									   <img src="../../../static/main/Separator.png" class="gridContentpic"/>
							   	     </div>
							   	   </el-col>
							   	   <el-col :span="3">
							   	     <div class="gridContent">
									   <span>进给倍率</span>
							   	       <span>{{GetArrayValue('pmc_rdpmcrng')}}</span>
									   <img src="../../../static/main/Separator.png" class="gridContentpic"/>
							   	     </div>
							   	   </el-col>
							   	   <el-col :span="5">
							   	     <div class="gridContent">
									   <span style="white-space: nowrap;">进给速度(mm/min)</span>
							   	       <span>{{GetArrayValue('cnc_actf')}}</span>
									   <img src="../../../static/main/Separator.png" class="gridContentpic"/>
							   	     </div>
							   	   </el-col>
							   	   <el-col :span="3">
							   	     <div class="gridContent">
									   <span>最大轴数</span>
							   	       <span>{{GetArrayValue('cnc_maxaxis')}}</span>
									   <img src="../../../static/main/Separator.png" class="gridContentpic"/>
							   	     </div>
							   	   </el-col>
								   <el-col :span="3">
								     <div class="gridContent">
								       <span>有效轴数</span>
								       <span>-</span>
								     </div>
								   </el-col>
							   	 </el-row>	
							   </div> -->
							   <div class="Regional_r_b">
									<div class="information">
										<div class="information_title">运行信息</div>
										<div class="information_con">
											<el-row :gutter="10">
											  <el-col :span="4">
											    <div class="mes" v-ellipsis:topstart="GetArrayValue('cnc_proname')">
											      <span>主轴转速：</span>
											      <span>{{GetArrayValue('cnc_proname')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>进给倍率：</span>
											      <span>{{GetArrayValue('cnc_currpg')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="4">
											    <div class="mes">
											      <span>进给速度：</span>
											      <span>{{GetArrayValue('cnc_currpg')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="6">
											    <div class="mes">
											      <span>最大轴数：</span>
											      <span>{{GetArrayValue('cnc_currpg')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="4">
											    <div class="mes">
											      <span>有效轴数：</span>
											      <span>{{GetArrayValue('cnc_currpg')}}</span>
											    </div>
											  </el-col>
											</el-row>	
										</div>
									</div>
									<div class="information">
										<div class="information_title">加工信息</div>
										<div class="information_con">
											<el-row :gutter="10">
											  <el-col :span="4">
											    <div class="mes" v-ellipsis:topstart="GetArrayValue('cnc_modelName')">
											      <span>运行模式：</span>
											      <span>{{GetArrayValue('cnc_modelName')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>加工状态：</span>
											      <span>-</span>
											    </div>
											  </el-col>
											  <el-col :span="4">
											    <div class="mes">
											      <span>机床报警：</span>
											      <span>-</span>
											    </div>
											  </el-col>
											  <el-col :span="6">
											    <div class="mes">
											      <span>急停状态：</span>
											      <span>{{GetArrayValue('cnc_emc')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="4">
											    <div class="mes">
											      <span>轴移动状态：</span>
											      <span>-</span>
											    </div>
											  </el-col>
											</el-row>	
										</div>
									</div>
									<div class="information">
										<div class="information_title">程序信息</div>
										<div class="information_con">
											<el-row :gutter="10">
											  <el-col :span="4">
											    <div class="mes" v-ellipsis:topstart="GetArrayValue('cnc_proname')">
											      <span>执行型号：</span>
											      <span>{{GetArrayValue('cnc_proname')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="20">
											    <div class="mes">
											      <span>执行代码：</span>
											      <span>{{GetArrayValue('cnc_currpg')}}</span>
											    </div>
											  </el-col>
											</el-row>	
										</div>
									</div>
									<div class="information Axis">
										<div class="information_title">轴信息</div>
										<div class="information_con">
											<el-row :gutter="10">
											  <el-col :span="4">
											    <div class="mes">
											      <span>绝对坐标</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>X：</span>
											      <span>{{GetArrayValue('cnc_rdabsX')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>Y：</span>
											      <span>{{GetArrayValue('cnc_rdabsY')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>Z：</span>
											      <span>{{GetArrayValue('cnc_rdabsZ')}}</span>
											    </div>
											  </el-col>
											</el-row>	
											<el-row :gutter="10">
											  <el-col :span="4">
											    <div class="mes">
											      <span>机床坐标</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>X：</span>
											      <span>{{GetArrayValue('cnc_rdmachX')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>Y：</span>
											      <span>{{GetArrayValue('cnc_rdmachY')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>Z：</span>
											      <span>{{GetArrayValue('cnc_rdmachZ')}}</span>
											    </div>
											  </el-col>
											</el-row>	
											<el-row :gutter="10">
											  <el-col :span="4">
											    <div class="mes">
											      <span>相对坐标</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>X：</span>
											      <span>{{GetArrayValue('cnc_rdrelX')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>Y：</span>
											      <span>{{GetArrayValue('cnc_rdrelY')}}</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>Z：</span>
											      <span>{{GetArrayValue('cnc_rdrelZ')}}</span>
											    </div>
											  </el-col>
											</el-row>	
										</div>
									</div>
									<div class="information Axis BottomAxis"
									v-loading="loading2"
									element-loading-text="加载中"
									element-loading-spinner="el-icon-loading"
									element-loading-background="rgba(1,34,48, 0)">
										<div class="information_title">刀具寿命</div>
										<div class="information_con toolManagement" v-if="CutterData.length>0">
											<el-row :gutter="10" v-for="(item,index) in CutterData" :key="index">
											  <el-col :span="4">
											    <div class="mes">
											      <span>刀具{{(item.toolNo/1)}}</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>额定寿命：</span>
											      <span>{{item.toolInitLife}}</span>
											    </div>
											  </el-col>
											  <el-col :span="5">
											    <div class="mes">
											      <span>当前寿命：</span>
											      <span>{{item.toolLife}}</span>
											    </div>
											  </el-col>
											</el-row>
										</div>
										<span v-show="emptyMsg2" class="icon">
										  <img :src="empty" class="icon-img">
										  <div class="icon-text">暂无数据</div>
										</span>
									</div>
							   </div>
						   </div>
						   <!--end-->
						   <!--设备实时绩效 start-->
						   <div class="Regional_r2" v-if="activeIndex===1">
							   <!-- <div class="Regional_r_t2">
								   <el-row :gutter="20">
								     <el-col :span="6">
								       <div class="RowDIv RowDIv1" 
										v-loading="loading4"
										element-loading-text="加载中"
										element-loading-spinner="el-icon-loading"
										element-loading-background="rgba(1,34,48, 0)">
								         <span>运行时长(分钟)</span>
										 <span style="color: #00d392;">{{WorkDayResultObj.WorkingTime}}</span>
										 <img src="../../../static/images/Runtime.png"/>
								       </div>
								     </el-col>
								     <el-col :span="6">
								       <div class="RowDIv RowDIv2"
									   v-loading="loading4"
									   element-loading-text="加载中"
									   element-loading-spinner="el-icon-loading"
									   element-loading-background="rgba(1,34,48, 0)">
								         <span>停机时长(分钟)</span>
								         <span style="color: #ff7210;">{{WorkDayResultObj.ShutDownTime}}</span>
								         <img src="../../../static/images/Shutdownduration.png"/>
								       </div>
								     </el-col>
								     <el-col :span="6">
								       <div class="RowDIv RowDIv3"
									   v-loading="loading4"
									   element-loading-text="加载中"
									   element-loading-spinner="el-icon-loading"
									   element-loading-background="rgba(1,34,48, 0)">
								         <span>计划产量(件)</span>
								         <span style="color: #00d392;">{{WorkDayResultObj.PlanAmount}}</span>
								         <img src="../../../static/images/Plannedproduction.png"/>
								       </div>
								     </el-col>
								     <el-col :span="6">
								       <div class="RowDIv RowDIv4"
									   v-loading="loading4"
									   element-loading-text="加载中"
									   element-loading-spinner="el-icon-loading"
									   element-loading-background="rgba(1,34,48, 0)">
								         <span>实际产量(件)</span>
								         <span style="color: #0cc0ff;">{{WorkDayResultObj.ActualAmount}}</span>
								         <img src="../../../static/images/Output.png"/>
								       </div>
								     </el-col>
								   </el-row>	
							   </div> -->
							   <div class="Regional_r_b2">
								   <div class="information Axis2">
								   	<div class="information_title" style="padding-bottom: 5px;">
										当前生产
										<div class="title_mes">
										  <span>加工产品：</span>
										  <span style="color: #02a577!important;" v-if="WorkDayResultObj.ProductName">{{WorkDayResultObj.ProductName}}</span>
										  <span v-else>-</span>
										</div>
									</div>
								   	<div class="information_con">
								   		<el-row :gutter="10">
								   		  <el-col :span="4">
								   		    <div class="mes" v-ellipsis:topstart="WorkDayResultObj.ProgramName">
								   		      <span>程序号：</span>
								   		      <span>{{WorkDayResultObj.ProgramName}}</span>
								   		    </div>
								   		  </el-col>
								   		  <el-col :span="4">
								   		    <div class="mes">
								   		      <span>工序：</span>
								   		      <span>{{WorkDayResultObj.WorkOrder}}</span>
								   		    </div>
								   		  </el-col>
								   		  <el-col :span="4">
								   		    <div class="mes">
								   		      <span>利用率：</span>
								   		      <span>{{WorkDayResultObj.UtilizationRate}}</span>
								   		    </div>
								   		  </el-col>
								   		  <el-col :span="4">
								   		    <div class="mes">
								   		      <span>加工出数：</span>
								   		      <span>{{WorkDayResultObj.WorkAmount}}</span>
								   		    </div>
								   		  </el-col>
								   		  <el-col :span="4">
								   		    <div class="mes">
								   		      <span>计划产量：</span>
								   		      <span>{{WorkDayResultObj.WorkAmount}}</span>
								   		    </div>
								   		  </el-col>
								   		</el-row>	
								   	</div>
									<div class="information_con" style="padding-bottom: 8px;">
										<el-row :gutter="10">
										  <el-col :span="4">
										    <div class="mes">
										      <span>理论节拍：</span>
										      <span>{{WorkDayResultObj.CycleTime}}秒</span>
										    </div>
										  </el-col>
										  <el-col :span="4">
										    <div class="mes">
										      <span>实际节拍：</span>
										      <span>{{WorkDayResultObj.ActualCycleTime}}秒</span>
										    </div>
										  </el-col>
										  <el-col :span="4">
										    <div class="mes">
										      <span>允许等待：</span>
										      <span>{{WorkDayResultObj.WaitTime}}秒</span>
										    </div>
										  </el-col>
										  <el-col :span="4">
										    <div class="mes">
										      <span>小时产出：</span>
										      <span>{{WorkDayResultObj.PerHourAmount}}件</span>
										    </div>
										  </el-col>
										  <el-col :span="4">
										    <div class="mes">
										      <span>实际产量：</span>
										      <span>{{WorkDayResultObj.PerHourAmount}}件</span>
										    </div>
										  </el-col>
										</el-row>	
									</div>
								   </div>
								   <div class="information_title">
								   	状态履历
								   	<div class="title_mes">
								   	  <span>设备稼动率：</span>
								   	  <span style="color: #02a577!important;" >{{WorkDayResultObj.ActiveRate}}%</span>
								   	</div>
								   </div>
								   <!--echarts图表-->
								   <div class="echartsCont">
									  <el-row :gutter="20">
									    <!-- <el-col :span="25"> -->
									    <el-col :span="4">
									      <div class="ConDIv">
													<span class="circle1"></span>
													<span>加工时间(分钟)</span>
										   		<span class="cirColor1">{{WorkDayResultObj.WorkingTime}}</span>
									      </div>
									    </el-col>
									    <el-col :span="4">
									      <div class="ConDIv">
													<span class="circle2"></span>
													<span>停机时间(分钟)</span>
													<span class="cirColor2">{{WorkDayResultObj.ShutDownTime}}</span>
									      </div>
									    </el-col>
									    <el-col :span="4">
									      <div class="ConDIv">
													<span class="circle3"></span>
									        <span>停机次数(次)</span>
									        <span class="cirColor3">{{WorkDayResultObj.ShutDownCount}}</span>
									      </div>
									    </el-col>
									    <el-col :span="4">
									      <div class="ConDIv">
													<span class="circle4"></span>
									        <span>故障时间(分钟)</span>
									        <span class="cirColor4">{{WorkDayResultObj.BadTime}}</span>
									      </div>
									    </el-col>
											<el-col :span="4">
										   <div class="ConDIv">
													<span class="circle5"></span>
													<span>故障次数(次)</span>
													<span class="cirColor5">{{WorkDayResultObj.BadCount}}</span>
										   </div>
											</el-col>
											<el-col :span="4">
										   <div class="ConDIv">
													<span class="circle6"></span>
													<span>离线时间(分钟)</span>
													<span class="cirColor6">{{WorkDayResultObj.ShutDownTime}}</span>
										   </div>
											</el-col>
									  </el-row>	 
								   </div>
								   <!--图表-->
								   <div class="LineChart" v-loading="loading4"
									   element-loading-text="加载中"
									   element-loading-spinner="el-icon-loading"
									   element-loading-background="rgba(1,34,48, 0)">
								   	 <div key="myCharts" id="main"></div>
									<span v-show="emptyMsg4" class="icon">
									   <img :src="empty" class="icon-img">
									   <div class="icon-text">暂无数据</div>
									 </span>
								   </div>
								   
							   </div>
						   </div>
						   <!--end-->
						   <!--用电状态监测 start-->
						   <div class="Regional_r2" v-if="activeIndex===2">
							   	<!-- <div class="Regional_r_t2">
										<el-row :gutter="20">
											<el-col :span="6">
												<div class="RowDIv RowDIvImg1"
														v-loading="loading5"
													element-loading-text="加载中"
													element-loading-spinner="el-icon-loading"
													element-loading-background="rgba(1,34,48, 0)">
												<span>实时功率(kW)</span>
												<span style="color: #00d392;">{{ContentDate[0]}}</span>
												<img src="../../../static/images/Realtimepower.png"/>
												</div>
											</el-col>
											<el-col :span="6">
												<div class="RowDIv RowDIvImg2"
												v-loading="loading5"
												element-loading-text="加载中"
												element-loading-spinner="el-icon-loading"
												element-loading-background="rgba(1,34,48, 0)">
												<span>日最高功率(kW)</span>
												<span style="color: #ebca27;">{{ContentDate[1]}}</span>
												<img src="../../../static/images/Dailymaximumpower.png"/>
												</div>
											</el-col>
											<el-col :span="6">
												<div class="RowDIv RowDIvImg3"
												v-loading="loading5"
												element-loading-text="加载中"
												element-loading-spinner="el-icon-loading"
												element-loading-background="rgba(1,34,48, 0)">
												<span>功率预警(kW)</span>
												<span style="color: #d10132;">{{ContentDate[2]}}</span>
												<img src="../../../static/images/Powerwarning.png"/>
												</div>
											</el-col>
											<el-col :span="6">
												<div class="RowDIv RowDIvImg4"
												v-loading="loading5"
												element-loading-text="加载中"
												element-loading-spinner="el-icon-loading"
												element-loading-background="rgba(1,34,48, 0)">
												<span>日用电量(kWh)</span>
												<span style="color: #0cc0ff;">{{ContentDate[3]}}</span>
												<img src="../../../static/images/powerconsumption.png"/>
												</div>
											</el-col>
										</el-row>	
									</div> -->
							   <div class="Regional_r_b2">
								   <div class="FilterCriteria">
									   <div class="miniTab" v-for="(tab,index) in DataArry" :key="index" @click="minitap(tab.id)" :class="dateType==tab.id ? 'active2' : ''">{{tab.name}}</div>
								   </div>
								   <div class="amchartCont" v-loading="loading3"
								   element-loading-text="加载中"
								   element-loading-spinner="el-icon-loading"
								   element-loading-background="rgba(1,34,48, 0)">
								   <span v-show="errorMsg3" class="icon">
								     <img :src="error" class="icon-img">
								     <div class="icon-text">系统错误</div>
								   </span>
								   <span v-show="emptyMsg3" class="icon">
								     <img :src="empty" class="icon-img">
								     <div class="icon-text">暂无数据</div>
								   </span>
								   <span v-show="noAuthMsg3" class="icon">
								     <div class="icon-text">没有权限</div>
								   </span>
									<!--日期-->
									<span class="timeDiv">日期：</span>
									<day @selectedDate="selecteBeginTime" :day="BeginTime" :btn="false" :label="false"></day>
								   <!--图表-->
								   <multiLine
									 rotation=true
									 ref="multiLine"
									 :data="data3"
									 :colors="colors3"
									 :lineColors="lineColors3"
									 :selectable="false"
									 :defaultSelectable="false"></multiLine>
								   <!-- 用电量 -->
							<!-- 	   <stackColumn
									 :data="data3"
									 :colors="colors3"
									 :selectable="false"
									 :isSchema="false"
									 :rotation="true"
									 :ShowChart="true"></stackColumn> -->
								   </div>
							   </div>
						   </div>
						   <!--end-->
					   </el-col>
				   </el-row>
			   </div>
			   
		   </el-col>
		</el-row>
	</div>
</template>

<script>
  import {
    formatDate,getNowFormatDate
  } from '@/common/js/date.js'
import { isEmpty,convert } from '@/common/js/base.js'
import {mapGetters,mapMutations} from 'vuex'
import headers from '@/components/header/headermain.vue'
import $ from 'jquery'
import empty from '@/assets/icons/empty02.png'
import error from '@/assets/icons/error.png'
// import multiLine from './components/amchart/multiLine_serie'
import multiLine from './components/amchart/multiLine'
import stackColumn from './components/amchart/stackColumn_ydl'
import day from '@/components/select/day'
import { GetEquipmentElectritityDetail,GetEquipmentImage,GetEquipmentCollectDataList,GetEquipmentPerformanceInfo,
GetEquipmentElectritityInfo,GetSelectedEquipmentMonitor, GetEuipmentPars } from "@/api/EquipmentOperationMonitoring"; //页面接口
import { GetManufacturingTree } from "@/api/EquipmentOperationAnalysis"; //页面接口
  export default {
    components: {
	  headers,
	  multiLine,
	  stackColumn,
	  day
    },
    data() {
      return {
		Header_title:'设备运行监控',
		btnName:'返回上一级',
		// BackUrl:'/MonitoringDetails',
		empty:empty,
		error:error,
		locationId:this.$route.query.locationId,
		List:[{
			name:'设备工作状态',
			id:1
		},{
			name:'设备实时绩效',
			id:2
		},{
			name:'用电状态监测',
			id:3
		}],
		colorStyle:'#ffffff',
		colorStyle2:null,
		colorStyle3:null,
		myChart:null,
		activeIndex:0,
		isActiveTab1:true,
		isActiveTab2:false,
		isActiveTab3:false,
		DataArry:[{
			name:'电流',
			id:1
		},{
			name:'电压',
			id:2
		},{
			name:'功率',
			id:3
		},{
			name:'用电量',
			id:4
		}],
		cnc_srate:0,
		cnc_rdspload:0,
		UtilizationRate:0,
		ActiveRate:0,
		tmp1:0,
		tmp2:0,

		dateType:1,
		BeginTime: formatDate(new Date(), 'yyyy-MM-dd'),
		ChartsTime:formatDate(new Date(), 'yyyy-MM-dd'),
		// data:JSON.parse(this.$route.query.data),
		data:[],
		dataTree:[],
		data2:[],
		// Name:this.$route.query.Name,
		// Level:this.$route.query.Level,
		AffiliatedUnit:this.$route.query.AffiliatedUnit,
		// Content:JSON.parse(this.$route.query.content),
		Content:{
			EquipmentStatus:null,
			EquipmentStatusName:null,
			EquipmentName:null,
			ModelName:null,
			EquipmentTypeName:null,
		},
		defaultProps: {
		  children: 'Children',
		  label: 'Name'
		},
		
		data3:null,
		
		loading3:false,
		emptyMsg3:false,
		errorMsg3:false,
		noAuthMsg3:false,
		
		loading4:false,
		emptyMsg4:false,
		WorkDayResultObj:{},
		WorkDayResultList:[],
		
		colors3: {}, //橙，绿
		lineColors3: {},
		Bgurl:null,
		loading:false,
		emptyMsg:true,
		srcList:[],
		
		loading2:false,
		emptyMsg2:true,
		DataList:[],
		CutterData:[],
		loading5:false,
		ContentDate:[],
		// EquipmentIds:'',
		EquipmentIds:this.$route.query.EquipmentId,
		// NavName:'',
		current_node_key:'',
		MonitorList:[],
		BgColor:this.$store.state.testTxt.tips,
		BackgroundImg:sessionStorage.getItem('BgColorType'),
		isIconOpen:false,//默认关闭
		isTreeCell:0,
		isCoentCell:24,
		groupTitle: this.$route.query.title,
	  }
    },
    computed:{
      ...mapGetters(['expansion'])
    },
    created(){
      
    },
    watch: {
	  '$store.state.testTxt.tips'(newVal,oldVal){
			this.BgColor = newVal;
			if(this.BgColor=='white'){
				this.BackgroundImg = 'white';
				if(this.activeIndex==1){
					//刷新legend颜色
					this.GetEquipmentPerformanceInfos();//设备实时绩效
				}
			}else{
				this.BackgroundImg = 'black';
				if(this.activeIndex==1){
					//刷新legend颜色
					this.GetEquipmentPerformanceInfos();//设备实时绩效
				}
			}
		},
      dateType(val){
        this.dateType = val;
        this.getRecords3();
      },
      BeginTime(val){
        this.getRecords3();
      },
	  expansion(v){
		if(this.activeIndex==1){
			this.GetEquipmentPerformanceInfos();//设备实时绩效
			if (this.myChart) {
				this.myChart.resize();
			}
		}
	  },
    },
	activated(){
		
	},
	mounted() {
		this.initsTree()
		this.GetEuipmentTabPars()
		//设备状态列表
		this.GetSelectedEquipmentMonitors();





	
	 this.GetEquipmentImages();
	 if(this.activeIndex==0){
		this.GetEquipmentCollectDataLists();
	 }else if(this.activeIndex==1){
		 this.$nextTick(() => {
			this.GetEquipmentPerformanceInfos();//设备实时绩效
		});
	 }else{
		 this.GetEquipmentElectritityInfos();//用电状态监控
	 }
	 window.addEventListener('resize', ()=>{
	   if(this.$route.path == '/StatusDetails'){
		   if(this.activeIndex==1){
		   		 this.$nextTick(() => {
		   			this.GetEquipmentPerformanceInfos();//设备实时绩效
		   		});
			}
	   }
	 });
	},
    methods: {
			initsTree(){
				this.loading = true;
				this.emptyMsg = false;
				this.dataTree = [];
				GetManufacturingTree({
					AgencyId:sessionStorage.getItem('agencyId'),//机构id
					LocationId:this.locationId,//厂房
				}).then(res => {
				let obj = res.Content;
				if(res.Code==-1) {
					this.loading = false;
					this.emptyMsg = true;
					this.dataTree = [];
					this.$notify.info({
						title:'消息',
						message: '查询失败'
					});
					return;
				}else if(obj.length == 0) {
					this.loading = false;
					this.emptyMsg = true;
					this.dataTree = [];
					this.$notify.info({
						title: '消息',
						message:'数据为空'
					});
					return;
				}
					this.loading = false;
					this.dataTree = this.getNewTree(obj);
					this.$nextTick(()=> {
						this.$refs.tree.setCurrentKey(this.EquipmentIds);
					})
				}).catch(err => {
					this.loading = false;
					this.emptyMsg = true;
				})
			},
			GetEuipmentTabPars(){
				this.cnc_srate = 0;
				this.cnc_rdspload = 0;
				this.UtilizationRate = 0;
				this.ActiveRate = 0;
				this.tmp1 = 0;
				this.tmp2 = 0;
				GetEuipmentPars({
					AgencyId:sessionStorage.getItem('agencyId'),//机构id
					EquipmentId:this.EquipmentIds
				}).then(res => {
					let obj = res.Content;
					if(res.Code == -1){
						this.$notify.info({
							title: '消息',
							message:'获取失败'
						});
						return;
					}
					this.cnc_srate = Number(obj.cnc_srate);
					this.cnc_rdspload = Number(obj.cnc_rdspload);
					this.UtilizationRate = obj.UtilizationRate;
					this.ActiveRate = obj.ActiveRate;
					this.tmp1 = obj.tmp1;
					this.tmp2 = obj.tmp2;
				}).catch(err => {
					this.cnc_srate = 0;
					this.cnc_rdspload = 0;
					this.UtilizationRate = 0;
					this.ActiveRate = 0;
					this.tmp1 = 0;
					this.tmp2 = 0;
				})
			},
			handleTreeClick(){
				this.isIconOpen = !this.isIconOpen;
				if (this.isIconOpen) {
					this.isTreeCell = 4;
					this.isCoentCell = 20;
				}else{
					this.isTreeCell = 0;
					this.isCoentCell = 24;
				}
				// 收缩树结构 resize尺寸
				if(this.activeIndex==1){
					this.$nextTick(() => {
						if (this.myChart) {
							this.myChart.resize();
						}
					});
				}else if(this.activeIndex == 2){
					this.$nextTick(() => {
						if (this.$refs.multiLine.myChart) {
							this.$refs.multiLine.myChart.resize();
						}
					});
				}
			},
		handleNodeClick (data) {
		  if(data.Type==5){
			// this.NavName = data.Name;
			this.EquipmentIds = data.Id;
			this.GetEquipmentImages();
			//设备状态列表
			this.GetSelectedEquipmentMonitors();
			
			if(this.activeIndex==0){
				this.GetEquipmentCollectDataLists();
			}else if(this.activeIndex==1){
				this.GetEquipmentPerformanceInfos();
			}else{
				this.GetEquipmentElectritityInfos();//用电状态监控
				this.getRecords3();
			}
		  }else{
			// this.$notify.info({
			//   title:'消息',
			//   message: '请点击设备进行筛选'
			// });  
		  }
		},
		// 递归遍历树,通过map遍历直接修改原数组数据，数组其他的数据不变
		getNewTree(obj){
			obj.map(item=>{
			  if(item.Children&&item.Children.length>0){
				item.disabled = true;
				this.getNewTree(item.Children)
			  }
			})
			return obj
		},
		tap(index){
		  this.activeIndex=index;
		  if(index==0){
				this.isActiveTab1 = true;
				this.isActiveTab2 = false;
				this.isActiveTab3 = false;

				this.GetEquipmentCollectDataLists();//设备工作状态
				this.colorStyle = '#ffffff';
				this.colorStyle2 = null;
				this.colorStyle3 = null;
		  }else if(index==1){
				this.isActiveTab1 = false;
				this.isActiveTab2 = true;
				this.isActiveTab3 = false;

				this.$nextTick(() => {
					this.colorStyle = null;
					this.colorStyle2 = '#ffffff';
					this.colorStyle3 = null;
					this.GetEquipmentPerformanceInfos();//设备实时绩效
				});
		  }else if(index==2){
				this.isActiveTab1 = false;
				this.isActiveTab2 = false;
				this.isActiveTab3 = true;

				this.$nextTick(() => {
					this.colorStyle = null;
					this.colorStyle2 = null;
					this.colorStyle3 = '#ffffff';
					this.GetEquipmentElectritityInfos();
					this.getRecords3();
				});
		  }
		},
		minitap(index){
			this.dateType=index;
		},
		GetEquipmentImages(){
			this.loading = true;
			this.emptyMsg = false;
			this.srcList = [];
			GetEquipmentImage({
			    EquipmentId:this.EquipmentIds
			  }).then(res => {
				  if(res.Code!=0){
					 this.$notify.info({
					   title: '消息',
					   message:'图片获取为空'
					 });
					 this.emptyMsg = true;
					 return;
				  }
				this.Bgurl = 'data:image/png;base64,'+ res.Content;
				// console.log(this.Bgurl)
				this.srcList.push(this.Bgurl);
				this.loading = false;
			}).catch(err => {
			
			})
		},
		GetSelectedEquipmentMonitors(){
			this.loading = true;
			this.emptyMsg = false;
			this.MonitorList = [];
			GetSelectedEquipmentMonitor({
			  AgencyId:sessionStorage.getItem('agencyId'),//机构id
			  EquipmentId:this.EquipmentIds
			}).then(res => {
			let obj = res.Content;
			 if(res.Code==-1) {
			   this.loading = false;
			   this.emptyMsg = true;
			   this.MonitorList = [];
			   this.$notify.info({
			     title:'消息',
			     message: '查询失败'
			   });
			   return;
			 }else if(obj.length == 0) {
			  this.loading = false;
			  this.emptyMsg = true;
			  this.MonitorList = [];
			   this.$notify.info({
			     title: '消息',
			     message:'数据为空'
			   });
			   return;
			 }
			 this.loading = false;
			 this.MonitorList = obj.MonitorList[0];
			 this.Content = this.MonitorList;
			}).catch(err => {
			this.loading = false;
			this.emptyMsg = true;
			})
		},
		GetEquipmentCollectDataLists(){
			this.loading2 = true;
			this.emptyMsg2 = false;
			this.DataList = [];
			this.CutterData = [];
			GetEquipmentCollectDataList({
				AgencyId:sessionStorage.getItem('agencyId'),
				EquipmentId:this.EquipmentIds
			}).then(res => {
			   let obj = res.Content;
			   if(res.Code==-1){
			     this.DataList = [];
				 this.CutterData = [];
				 this.emptyMsg2 = true;
			     this.loading2 = false;
			     this.$notify.info({
			       title: '消息',
			       message: '获取信息失败'
			     });
			     return;
			   }else if(obj.length==0){
			     this.DataList = [];
				 this.CutterData = [];
				 this.emptyMsg2 = true;
			     this.$notify.info({
			       title: '消息',
			       message:'暂无数据'
			     });
			     this.loading2 = false;
			     return;
			   }
			   this.DataList = obj.RealtimeData;
			   //刀具信息
			   this.CutterData = obj.CutterData;
			   if(this.CutterData.length>0){
				this.emptyMsg2 = false;
			   }else{
				this.emptyMsg2 = true;   
			   }
			   this.loading2= false;
			}).catch(err => {
				this.loading2= false;
				this.emptyMsg2 = true;
				this.DataList = [];
				this.CutterData = [];
			})
		},
		GetArrayValue(val){
		if(this.DataList.length>0){
			let result = this.DataList.filter(item=>item.VarNo === val);
			if(result.length>0){
				return result[0].VarVal
			}else{
				return 0
			}
		}
		},
		GetEquipmentPerformanceInfos(){
			this.loading4 = true;
			this.emptyMsg4 = false;
			this.WorkDayResultObj = {};
			this.WorkDayResultList = [];
			GetEquipmentPerformanceInfo({
				AgencyId:sessionStorage.getItem('agencyId'),
				EquipmentId:this.EquipmentIds
			}).then(res => {
			   let obj = res.Content;
			   if(res.Code==-1){
			     this.WorkDayResultObj = {};
				 this.WorkDayResultList = [];
			     this.loading4 = false;
				 this.emptyMsg4 = true;
			     this.$notify.info({
			       title: '消息',
			       message: '获取信息失败'
			     });
			     return;
			   }else if(obj.Measures.length==0||obj.WorkDayResult==''){
			     this.WorkDayResultObj = {};
				 this.WorkDayResultList = [];
			     this.$notify.info({
			       title: '消息',
			       message:'暂无数据'
			     });
			     this.loading4 = false;
				 this.emptyMsg4 = true;
			   }
			   this.loading4= false;
			   this.WorkDayResultObj = obj.WorkDayResult;
			   this.WorkDayResultList = obj.Measures;
			   this.drawChart(this.WorkDayResultList);
			}).catch(err => {
				
			})
		},
		drawChart(res) {
		 let that = this
		 // 基于准备好的dom，初始化echarts实例
		 document.getElementById('main').style.width='100%';
		 document.getElementById('main').style.height='100%';
		 this.$echarts.init(document.getElementById('main')).dispose();
		 this.myChart = this.$echarts.init(document.getElementById("main"));
		 let Array = [];
		 let ArrayDate = [];
		 let colour='';
		 for(let i=0;i<res.length;i++){
			 Array.push(res[i].Name)
			 if(res[i].Name=='加工时间'){
				colour = '#38cb89' 
			 }else if(res[i].Name=='待机时间'){
				colour = '#009cff' 
			 }else if(res[i].Name=='停机时间'){
				colour = '#cccccc' 
			 }else if(res[i].Name=='报警时间'){
				colour = '#ff715b' 
			 }
			 let obj = {
				'itemStyle':{
					'normal': { 
					   'color':colour
					},
				},
				'name':res[i].Name,
				'value':[res[i].Value/1,res[i].StartTime,res[i].StopTime]
			 }
			 ArrayDate.push(obj)
		 }
		 var resultArr;
		 resultArr = Array.filter(function (item, index, self) {
		   return self.indexOf(item) == index;
		 });
		 var state = resultArr;
		 var colors = [];
		 for(let j=0;j<resultArr.length;j++){
			if(resultArr[j]=='加工时间'){
				colors.push('#38cb89');
			}else if(resultArr[j]=='待机时间'){
				colors.push('#009cff');
			}else if(resultArr[j]=='停机时间'){
				colors.push('#cccccc');
			}else if(resultArr[j]=='报警时间'){
				colors.push('#ff715b');
			}
		 }
		 var option = {
				 color: colors,
				 tooltip: {//提示框
					 formatter: function (params) {
						 return params.name + ':' + "<br />" + params.value[1] + '~' + params.value[2];
					 }//数据的值
				 },
				 legend: {//图例
					 data: state,
					 top: convert(10),
					 right:convert(10),
					 fontSize:convert(12),
					 selectedMode: false, // 图例设为不可点击
					 textStyle: {
						color:this.BgColor=='white'?'#333':'#fff',
					 }
				 },
				 grid: {//绘图网格
					 left:convert(12),
					 right:convert(12),
					 top: convert(40),
					 bottom: '0%',
					 containLabel: true
				 },
				 xAxis: {
					 type: 'time',               
					 interval: 3600  * 1000,   //以一个小时递增 
					 min:this.ChartsTime+" "+"00:00", //将data里最小时间的整点时间设为min,否则min会以data里面的min为开始进行整点递增
					 axisLabel: {
						 formatter: function (value) {
						 var date = new Date(value);
						 return getzf(date.getHours()) + ':00';  
						 function getzf(num) {
							if (parseInt(num) < 10) {  num = '0' + num; }  
							return num; 
						 }             
					   },       
					   textStyle: {
					   	  color:this.BgColor=='white'?'#333':'#fff',
					   }
					 }
				 },
				 yAxis: {
					 data: []
				 },
				 series: [
					 // 用空bar来显示三个图例
					 { name: state[0], type: 'bar', data: [] },
					 { name: state[1], type: 'bar', data: [] },
					 { name: state[2], type: 'bar', data: [] },
					 { name: state[3], type: 'bar', data: [] },
					 {
						 type: 'custom',                
						 renderItem: function (params, api) {//开发者自定义的图形元素渲染逻辑，是通过书写 renderItem 函数实现的
							 var categoryIndex = api.value(0);//这里使用 api.value(0) 取出当前 dataItem 中第一个维度的数值。
							 var start = api.coord([api.value(1), categoryIndex]); // 这里使用 api.coord(...) 将数值在当前坐标系中转换成为屏幕上的点的像素值。
							 var end = api.coord([api.value(2), categoryIndex]);
							 var height = api.size([0, 1])[1];
		  
							 return {
								 type: 'rect',// 表示这个图形元素是矩形。还可以是 'circle', 'sector', 'polygon' 等等。
								 // shape: echarts.graphic.clipRectByRect({ // 矩形的位置和大小。
								 //     x: start[0],
								 //     y: start[1] - height / 2,
								 //     width: end[0] - start[0],
								 //     height: height
								 // }, { // 当前坐标系的包围盒。
								 //     x: params.coordSys.x,
								 //     y: params.coordSys.y,
								 //     width: params.coordSys.width,
								 //     height: params.coordSys.height
								 // }),
								 shape:{
									x: start[0],
									y: start[1] - height / 2,
									width: end[0] - start[0],
									height: height
								 },
								 style: api.style()
							 };
						 },
						 encode: {
							 x: [1, 2], // data 中『维度1』和『维度2』对应到 X 轴
							 y: 0// data 中『维度0』对应到 Y 轴
						 },
						 data:ArrayDate
					 }
				 ]
			 };
		 
		  // 使用刚指定的配置项和数据显示图表。
		  this.myChart.setOption(option);
		  //  随外层div的大小变化自适应
		  window.addEventListener("resize", () => {
			if(this.activeIndex==1){
				 this.$nextTick(() => {
					this.drawChart(this.WorkDayResultList)
					this.myChart.resize();
				});
			 }
			
		  })
		},
		//用电状态监测
		GetEquipmentElectritityInfos(){
			this.loading5 = true;
			this.ContentDate = [];
			GetEquipmentElectritityInfo({
				AgencyId:sessionStorage.getItem('agencyId'),
				EquipmentId:this.EquipmentIds
			}).then(res => {
			   let obj = res.Content;
			   if(res.Code==-1){
				 this.ContentDate = [];
			     this.loading5 = false;
			     this.$notify.info({
			       title: '消息',
			       message: '获取信息失败'
			     });
			     return;
			   }else if(obj.length==0){
				 this.ContentDate = [];
			     this.$notify.info({
			       title: '消息',
			       message:'暂无数据'
			     });
			     this.loading5 = false;
			     return;
			   }
			   this.loading5= false;
			   this.ContentDate = obj;
			}).catch(err => {
				
			})
		},
		getRecords3(){
			this.loading3 = true
		    this.emptyMsg3 = false
		    this.errorMsg3 = false
		    this.noAuthMsg3 = false
		    this.data3 = null
			GetEquipmentElectritityDetail({
				AgencyId:sessionStorage.getItem('agencyId'),
				EquipmentId:this.EquipmentIds,
				Type:this.dateType,
			}).then(res => {
			  let obj = res.Content;
			  if (res.Code === -1) {
			    this.data3 = { Dimension: [], Measure: [] }
			    this.errorMsg3 = true
			    this.loading3 = false
			  		
			    return
			  } else if (isEmpty(obj) || isEmpty(obj.Dimension) || obj.Dimension.length == 0 ) {
			    this.data3 = { Dimension: [], Measure: [] } //清数据和坐标轴
			    this.loading3 = false
			    this.emptyMsg3 = true
			    return
			  }
			  this.data3 = obj;
			  this.loading3 = false
			}).catch(err => {
				this.data3 = { Dimension: [], Measure: [] } //清数据和坐标轴
				if (err.status == 403) {
				  //没有权限
				  this.noAuthMsg3 = true
				} else {
				  this.errorMsg3 = true
				}
				this.loading3 = false
			})
		},
		selecteBeginTime(val){
			this.BeginTime = formatDate(new Date(val), 'yyyy-MM-dd');
		}
    },
	beforeDestroy() {
	  window.removeEventListener("resize", () => {
			if (this.myChart) {
				this.myChart.resize();
			}
	  });
	},
  }
</script>

<style scoped="scoped" lang="scss">
@import '@/assets/mixin.scss';
.StatusDetails{
	width: 100%;
	height: calc(100% - 90px);
}
/*tree的虚线样式*/
.el-tree{
	padding: 10px;
	font-size: 15px;
	background-color: transparent;
	@include font_color("font2_color_white");
}
/*tree的虚线样式*/
::v-deep .tree{
  .el-tree-node {
    position: relative;
    padding-left: 6px; // 缩进量
  }
  .el-tree-node__children {
    padding-left: 10px; // 缩进量
  }
  .el-tree-node__expand-icon{
	margin-left: 4px!important;
  }
  .tree-element {
  	background-color: transparent;
  	outline: none;
  	cursor: default;
  }
.el-tree-node__content{
  &:hover{
    background-color: transparent;
	color: #38cb89;
  }
  }
  // 竖线
  .el-tree-node::before {
    content: "";
    height: 100%;
    width: 1px;
    position: absolute;
    left: 3px;
    top: -26px;
    border-width: 1px;
    border-left: 1px dashed #bbbbbb;
  }
  // 当前层最后一个节点的竖线高度固定
  .el-tree-node:last-child::before {
    height: 38px; // 可以自己调节到合适数值
  }

  // 横线
  .el-tree-node::after {
    content: "";
    width: 15px;
    height: 20px;
    position: absolute;
    left:3px;
    top: 12px;
    border-width: 1px;
    border-top: 1px dashed #bbbbbb;
  }

  // 去掉最顶层的虚线，放最下面样式才不会被上面的覆盖了
  & > .el-tree-node::after {
    border-top: none;
  }
  & > .el-tree-node::before {
    border-left: none;
  }
	
  // 展开关闭的icon
  .el-tree-node__expand-icon{
    font-size: 15px;
    // 叶子节点（无子节点）
    &.is-leaf{
      color: transparent;
      // display: none; // 也可以去掉
			&:before{
        display: none;
      }
    }
  }
  .el-tree-node:focus>.el-tree-node__content{ //选中
    background-color: transparent;
  }
}
::v-deep .el-col-25{
	width: 20%;
}
.custom-tree-node {
  position: relative;
}
.mark{
	display: inline-block;
	margin-left: 10px;
	font-size: 16px;
	color: #cccccc;
	cursor: pointer;
}
.mark2{
	display: inline-block;
	margin-left: 10px;
	color: #cccccc;
}
.grid-content-main{
	@include background_color("background_color_white");
	// background: url('../../../static/main/EquipmentMonitoringBg.png');
	background: url('../../../static/images/bck_bord_big.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	padding-top: 20px;
	height: calc(100% - 30px);
}
.grid-content-height{
	height: 100%;
}
.cirColor1{
	color: #38cb89 !important;
}
.cirColor2{
	color: #ff9600 !important;
}
.cirColor3{
	color: #ff9600 !important;
}
.cirColor4{
	color: #ff715b !important;
}
.cirColor5{
	color: #ff715b !important;
}
.cirColor6{
	color: #cccccc !important;
}
.mb15{
	margin-right: 15px;
}
.Regional_rIndex1{
  z-index: 3;
}
.Regional_rIndex2{
  z-index: 2;
}
.Regional_rIndex3{
	z-index: 1;
}
.Regional_tabNor{
	background-image: url('../../../static/images/tab01-normal.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.Regional_tabAct{
	background-image: url('../../../static/images/tab01-active.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.Regional_tabNor2{
	background-image: url('../../../static/images/tab02-normal.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.Regional_tabAct2{
	background-image: url('../../../static/images/tab02-active.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.Regional_rTxt{
	font-size: 14px;
}
.Regional_rNum{
	font-family: 'GemunuLibre-Regular';
	font-size: 18px;
	color: #00d392;
}
@media only screen and (max-aspect-ratio: 1920/920 ) {
.disabled {
  display: inline-block;
  cursor: not-allowed;
  position: absolute;
  height: 27px;
  width: 210px;
  background: transparent!important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}
.el-tree{
	padding: 10px 10px 10px 10px;
	font-size: 15px;
}
.grid-content_l{
	width: 100%;
	height: 705px;
	border-radius: 4px;
	// @include background_color("background_color_white");
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 850px;
	background-repeat: no-repeat;
	position: relative;
}
.grid-tree-icon{
	width: 10px;
	height: 12px;
	display: inline-block;
}

.grid-content-open{
	width: 18px;
	height: 80px;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(100%, -60%);
	cursor: pointer;
	background-color: #062e3a;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}
.grid-content-close{
	width: 18px;
	height: 80px;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(-4px, -60%);
	cursor: pointer;
	background-color: #062e3a;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}

.TreeImg{
	display: inline-block;
	width: 16px;
	height: 17px;
}
.grid-content_r{
	width: 100%;
	// height: 850px;
	height: 100%;
	border-radius: 5px;
	// @include background_color("background_color_white");
	position: relative;
}
.HeaderTab{
	width: 98%;
	height: 50px;
	margin-left: 15px;
	line-height: 50px;
	font-size:18px;
	@include border_color("border_color_white");
}
.tabs{
	min-width:100px;
	padding-left: 0px;
	padding-right: 0px;
	height: 50px;
	line-height: 50px;
	margin-right: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
}
.active{
	color: #38cb89!important;
	border-bottom: 3px solid #38cb89;
}
.miniTab{
	min-width:50px;
	padding-left: 0px;
	padding-right: 0px;
	height: 30px;
	line-height: 30px;
	margin-right: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
}
.active2{
	color: #38cb89!important;
	border-bottom: 2px solid #38cb89;
}
.monitor_1{
	width: 100%;
	height: 50px;
	font-size: 16px;
	margin-top: 5px;
	@include font_color("font2_color_white");
	padding-left: 15px;
	line-height: 50px;
}
.monitor_r{
	width: 100%;
	height: 50px;
	line-height: 50px;
	margin-top: 5px;
	white-space: nowrap;
}
.StateStyle{
	display: inline-block;
	width: 135px;
	height: 100%;
}
.StateStyle .title{
	font-size: 16px;
	@include font_color("font2_color_white");
}
.StateStyle .num{
	font-size: 16px;
	@include font_color("font2_color_white");
}
.Regional_l{
	width: 100%;
	height: 700px;
	border-radius: 4px;
	margin-top: 5px;
	// margin-left: 8%;
	margin-left: 6%;
	@include background_color("background_color_white");
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 730px;
	background-repeat: no-repeat;
}
.RegionalImg{
	position: relative;
	width: 100%;
	height: 280px;
	text-align: center;
	margin-bottom: 20px;
}
.RegionalImg .img{
	display: inline-block;
	// width: 86%;
	width: 230px;
	height: auto;//220px
	// margin-left:7%;
	margin: 15px auto;
	// margin-top: 15px;
	cursor: pointer;
	vertical-align: middle;
}
.RegionalList{
	width: 92%;
	height: 50px;
	line-height: 50px;
	margin: 0 auto;
	@include border_color("border_color_white");
}
.RegionalList .span1{
	font-size: 15px;
	@include font_color("font7_color_white");
}
.RegionalList .span2{
	font-size: 15px;
	@include font_color("font2_color_white");
}
.Regional_r{
	// width: 96%;
	// margin-left: 2%;
	width: 97%;
	// height: auto;
	margin-left: 1%;
	border-radius: 2px;
	// margin-top: 5px;
}
.Regional_r_t{
	width: 100%;
	height:100px;
	@include background_color("background_color_white");
	border-radius: 4px;
	background-image: url('../../../static/main/Separatorbar.png');
	background-size: 100% 100px;
	background-repeat: no-repeat;
	background-position:center;
}
.Regional_r2{
	width: 97%;
	margin-left: 1%;
	border-radius: 2px;
	// margin-top: 18px;
}
.Regional_r_t2{
	width: 100%;
	height:120px;
}
.Regional_rBox{
	height: 6vh;
	display: flex;
}
.Regional_rItem1{
	width: 32.3%;
	height: 100%;
	cursor: pointer;
	padding-left: 20px;
}
.Regional_rItem2{
	width: 31.5%;
	height: 100%;
	cursor: pointer;
	padding-left: 6.5%;
	margin-left: -5%;
}

.RowDIv{
	position: relative;
	width: 100%;
	height: 120px;
	@include background_color("background_color_white");
	border-radius: 4px;
}
.RowDIv span:nth-child(1){
	position: absolute;
	top: 25px;
	left: 20px;
	font-size: 16px;
	color: #fff;
}
.RowDIv span:nth-child(2){
	position: absolute;
	top: 60px;
	left: 20px;
	font-size: 22px;
	font-weight: 600;
}
.RowDIv img{
	position: absolute;
	top: 28px;
	right: 22px;
	display: inline-block;
	width: 60px;
	height: 60px;
}
.RowDIv1{
	width: 100%;
	height: 120px;
	background-image: url('../../../static/main/RowDiv1.png');
	background-size: 100% 120px;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIv2{
	width: 100%;
	height: 120px;
	background-image: url('../../../static/main/RowDiv2.png');
	background-size: 100% 120px;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIv3{
	width: 100%;
	height: 120px;
	background-image: url('../../../static/main/RowDiv1.png');
	background-size: 100% 120px;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIv4{
	width: 100%;
	height: 120px;
	background-image: url('../../../static/main/RowDiv4.png');
	background-size: 100% 120px;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIvImg1{
	width: 100%;
	height: 120px;
	background-image: url('../../../static/main/RowDiv1.png');
	background-size: 100% 120px;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIvImg2{
	width: 100%;
	height: 120px;
	background-image: url('../../../static/main/RowDIvImg2.png');
	background-size: 100% 120px;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIvImg3{
	width: 100%;
	height: 120px;
	background-image: url('../../../static/main/RowDIvImg3.png');
	background-size: 100% 120px;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIvImg4{
	width: 100%;
	height: 120px;
	background-image: url('../../../static/main/RowDiv4.png');
	background-size: 100% 120px;
	background-repeat: no-repeat;
	background-position:center;
}
.gridContent{
padding-left: 20px;
position: relative;
}
.gridContentpic{
	position: absolute;
	top: -10px;
	width: 2px;
	height: 90px;
	right: 0;
}
.gridContent span:nth-child(1){
display: block;
font-size: 16px;
// @include font_color("font8_color_white");
color: #fff;
margin-top: 20px;
margin-bottom: 10px;
}
.gridContent span:nth-child(2){
display: block;
font-size: 22px;
font-weight: 600;
margin-top: 12px;
color: #1dac5d;
margin-bottom: 10px;
}
.Regional_r_b{
	width: 97%;
	height:627px;
	padding: 0 15px 15px 15px;
	@include background_color("background_color_white");
	border-radius: 4px;
	background-image: url('../../../static/main/BigBackground.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.Regional_r_b2{
	width: 97%;
	height:627px;
	padding: 0 15px 15px 15px;
	@include background_color("background_color_white");
	border-radius: 4px;
	background-image: url('../../../static/main/BigBackground.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
  .information{
	  width: 100%;
	  min-height:90px;
	  @include border_color("border_color_white");
  }
  .BottomAxis{
	  position: relative;
	  border-bottom: none!important;
  }
  .information_title{
	  font-size: 18px;
	  padding-top: 15px;
	  @include font_color("font2_color_white");
  }
  .toolManagement{
	max-height: 192px;
	overflow-y: auto;
	overflow-x: hidden;  
  }
  .information_con .mes{
	  height: 50px;
	  line-height: 50px;
  }
  .Axis .mes{
	  height: 40px;
	  line-height: 40px;
  }
  .Axis2 .mes{
  	  height: 30px;
  	  line-height: 30px;
  }
  .information_con .mes span:nth-child(1){
	  font-size: 16px;
	  @include font_color("font7_color_white");
  }
  .information_con .mes span:nth-child(2){
  	  font-size: 15px;
  	  @include font_color("font2_color_white");
  }
  .title_mes{
	  display: inline-block;
	  margin-left: 20px;
  }
  .title_mes span:nth-child(1){
  	  font-size: 16px;
	  @include font_color("font7_color_white");
  }
  .title_mes span:nth-child(2){
  	  font-size: 15px;
  	  @include font_color("font2_color_white");
  }
  .echartsCont{
	  padding-right: 10px;
  }
  .ConDIv{
	  position: relative;
	  width: 100%;
	  height:78px;
	  margin-top: 15px;
	  border-radius: 4px;
	  // @include background_color("background4_color_white");
	  background-image: url('../../../static/main/Statusresume.png');
	  background-size: 100% 78px;
	  background-repeat: no-repeat;
	  background-position:center;
	  
  }
  .ConDIv span:nth-child(1){
	  display: inline-block;
	  position: absolute;
	  left: 10px;
	  top: 16px;
  }
  .ConDIv span:nth-child(2){
  	  display: inline-block;
  	  position: absolute;
	  white-space:nowrap;
	  font-size: 16px;
	  color: #b3b3b3;
	  @include font_color("font7_color_white");
	  left: 32px;
	  top: 12px;
  }
  .ConDIv span:nth-child(3){
  	  display: inline-block;
  	  position: absolute;
	  font-size: 18px;
	  left: 32px;
	  top: 44px;
	  font-weight: 600;
	  // @include font_color("font2_color_white");
		font-family: 'GemunuLibre-Regular';
  }
  .circle1 {
	  display: inline-block;
	  width: 8px;   /* 宽度 */
	  height: 8px;  /* 高度 */
	  border-radius: 8px;   /* 边角半径 */
	  border: 4px solid #38cb89;   /* 边框 */
  }
  .circle2 {
  	  display: inline-block;
  	  width: 8px;   /* 宽度 */
  	  height: 8px;  /* 高度 */
  	  border-radius: 8px;   /* 边角半径 */
  	  border: 4px solid #ff9600;   /* 边框 */
  }
  .circle3 {
  	  display: inline-block;
  	  width: 8px;   /* 宽度 */
  	  height: 8px;  /* 高度 */
  	  border-radius: 8px;   /* 边角半径 */
  	  border: 4px solid #ff9600;   /* 边框 */
  }
  .circle4 {
  	  display: inline-block;
  	  width: 8px;   /* 宽度 */
  	  height: 8px;  /* 高度 */
  	  border-radius: 8px;   /* 边角半径 */
  	  border: 4px solid #ff715b;   /* 边框 */
  }
  .circle5 {
  	  display: inline-block;
  	  width: 8px;   /* 宽度 */
  	  height: 8px;  /* 高度 */
  	  border-radius: 8px;   /* 边角半径 */
  	  border: 4px solid #ff715b;   /* 边框 */
  }
  .circle6 {
  	  display: inline-block;
  	  width: 8px;   /* 宽度 */
  	  height: 8px;  /* 高度 */
  	  border-radius: 8px;   /* 边角半径 */
  	  border: 4px solid #cccccc;   /* 边框 */
  }
  .LineChart{
	position: relative;
	width: 100%;
	height: 320px;
  }
  .FilterCriteria{
    width: 100%;
    line-height: 30px;
    text-align: left;
    z-index: 9;
	font-size:16px;
	padding-top: 16px;
	@include border_color("border_color_white");
  }
  .amchartCont{
	  position: relative;
	  width: 100%;
	  height:480px;
	  padding-top:15px;
  }
  .timeDiv{
	display:inline-block;
	width: 60px;
	text-align: left;
	@include font_color("font2_color_white");
	font-size:18px;
  }
}
@media only screen and (min-aspect-ratio: 1920/1000 ) {
.disabled {
  display: inline-block;
  cursor: not-allowed;
  position: absolute;
  height: 2.7vh;
  width: 210px;
  background: transparent!important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}
.el-tree{
	padding: 10px 10px 10px 10px;
	font-size: 15px;
}
.grid-content_l{
	width: 100%;
	height: 70.5vh;
	border-radius: 4px;
	// @include background_color("background_color_white");
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 85vh;
	background-repeat: no-repeat;
	position: relative;
}
.grid-tree-icon{
	width: 10px;
	height: 12px;
	display: inline-block;
}

.grid-content-open{
	width: 18px;
	height: 8vh;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(100%, -60%);
	cursor: pointer;
	background-color: #062e3a;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 10;
}
.grid-content-close{
	width: 18px;
	height: 8vh;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translate(-4px, -60%);
	cursor: pointer;
	background-color: #062e3a;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
}

.TreeImg{
	display: inline-block;
	width: 16px;
	height: 1.7vh;
}
.grid-content_r{
	width: 100%;
	// height: 85vh;
	height: 100%;
	border-radius: 5px;
	// @include background_color("background_color_white");
	position: relative;
}
.HeaderTab{
	width: 98%;
	height: 5vh;
	margin-left: 15px;
	line-height: 5vh;
	font-size:18px;
	@include border_color("border_color_white");
}
.tabs{
	min-width:100px;
	padding-left: 0px;
	padding-right: 0px;
	height: 5vh;
	line-height: 5vh;
	margin-right: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
}
.active{
	color: #38cb89!important;
	border-bottom: 3px solid #38cb89;
}
.miniTab{
	min-width:50px;
	padding-left: 0px;
	padding-right: 0px;
	height: 3vh;
	line-height: 3vh;
	margin-right: 30px;
	cursor: pointer;
	display: inline-block;
	text-align: center;
}
.active2{
	color: #38cb89!important;
	border-bottom: 2px solid #38cb89;
}
.monitor_1{
	width: 100%;
	height: 5vh;
	font-size: 16px;
	margin-top: 0.5vh;
	@include font_color("font2_color_white");
	padding-left: 15px;
	line-height: 5vh;
}
.monitor_r{
	width: 100%;
	height: 5vh;
	line-height: 5vh;
	margin-top: 0.5vh;
	white-space: nowrap;
}
.StateStyle{
	display: inline-block;
	width: 135px;
	height: 100%;
}
.StateStyle .title{
	font-size: 16px;
	@include font_color("font2_color_white");
}
.StateStyle .num{
	font-size: 16px;
	@include font_color("font2_color_white");
}
.Regional_l{
	width: 100%;
	height: 70vh;
	border-radius: 4px;
	margin-top: 0.5vh;
	margin-left: 6%;
	@include background_color("background_color_white");
	background: url('../../../static/images/baseInfo_v.png');
	background-size: 100% 73vh;
	background-repeat: no-repeat;
}
.RegionalImg{
	position: relative;
	width: 100%;
	height: 28vh;
	text-align: center;
	margin-bottom: 2vh;
}
.RegionalImg .img{
	display: inline-block;
	// width: 86%;
	width: 230px;
	height: auto;//22vh
	// margin-left:7%;
	margin: 1.5vh auto;
	// margin-top: 1.5vh;
	cursor: pointer;
	vertical-align: middle;
}
.RegionalList{
	width: 94%;
	height: 5vh;
	line-height: 5vh;
	margin: 0 auto;
	@include border_color("border_color_white");
}
.Regional_r{
	width: 97%;
	// height: auto;
	margin-left: 1%;
	border-radius: 2px;
	// margin-top: 5px;
}
.Regional_r_t{
	width: 100%;
	height:10vh;
	@include background_color("background_color_white");
	border-radius: 4px;
	background-image: url('../../../static/main/Separatorbar.png');
	background-size: 100% 10vh;
	background-repeat: no-repeat;
	background-position:center;
}
.Regional_r2{
	width: 97%;
	margin-left: 1%;
	border-radius: 2px;
	// margin-top: 1.8vh;
}
.Regional_r_t2{
	width: 100%;
	height:12vh;
}

.Regional_rBox{
	height: 60px;
	display: flex;
}
.Regional_rItem1{
	width: 32.3%;
	height: 100%;
	cursor: pointer;
	padding-left: 20px;
}
.Regional_rItem2{
	width: 31.5%;
	height: 100%;
	cursor: pointer;
	padding-left: 6.5%;
	margin-left: -5%;
}

.RowDIv{
	position: relative;
	width: 100%;
	height: 12vh;
	@include background_color("background_color_white");
	border-radius: 4px;
}
.RowDIv span:nth-child(1){
	position: absolute;
	top: 2.5vh;
	left: 20px;
	font-size: 16px;
	color: #fff;
}
.RowDIv span:nth-child(2){
	position: absolute;
	top: 6vh;
	left: 20px;
	font-size: 22px;
	font-weight: 600;
}
.RowDIv img{
	position: absolute;
	top: 2.8vh;
	right: 22px;
	display: inline-block;
	width: 60px;
	height: 60px;
}
.RowDIv1{
	width: 100%;
	height: 12vh;
	background-image: url('../../../static/main/RowDiv1.png');
	background-size: 100% 12vh;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIv2{
	width: 100%;
	height: 12vh;
	background-image: url('../../../static/main/RowDiv2.png');
	background-size: 100% 12vh;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIv3{
	width: 100%;
	height: 12vh;
	background-image: url('../../../static/main/RowDiv1.png');
	background-size: 100% 12vh;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIv4{
	width: 100%;
	height: 12vh;
	background-image: url('../../../static/main/RowDiv4.png');
	background-size: 100% 12vh;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIvImg1{
	width: 100%;
	height: 12vh;
	background-image: url('../../../static/main/RowDiv1.png');
	background-size: 100% 12vh;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIvImg2{
	width: 100%;
	height: 12vh;
	background-image: url('../../../static/main/RowDIvImg2.png');
	background-size: 100% 12vh;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIvImg3{
	width: 100%;
	height: 12vh;
	background-image: url('../../../static/main/RowDIvImg3.png');
	background-size: 100% 12vh;
	background-repeat: no-repeat;
	background-position:center;
}
.RowDIvImg4{
	width: 100%;
	height: 12vh;
	background-image: url('../../../static/main/RowDiv4.png');
	background-size: 100% 12vh;
	background-repeat: no-repeat;
	background-position:center;
}
.gridContent{
padding-left: 20px;
position: relative;
}
.gridContentpic{
	position: absolute;
	top: -1vh;
	width: 2px;
	height: 9vh;
	right: 0;
}
.gridContent span:nth-child(1){
display: block;
font-size: 16px;
// @include font_color("font8_color_white");
color: #fff;
margin-top: 2vh;
margin-bottom: 1vh;
}
.gridContent span:nth-child(2){
display: block;
font-size: 22px;
font-weight: 600;
margin-top: 1.2vh;
color: #38cb89;
}
.Regional_r_b{
	width: 97%;
	height:62.7vh;
	padding: 0 15px 1.5vh 15px;
	@include background_color("background_color_white");
	border-radius: 4px;
	background-image: url('../../../static/main/BigBackground.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.Regional_r_b2{
	width: 97%;
	height:62.7vh;
	padding: 0 15px 1.5vh 15px;
	@include background_color("background_color_white");
	border-radius: 4px;
	background-image: url('../../../static/main/BigBackground.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position:center;
}
.information{
	  width: 100%;
	  min-height:9vh;
	  @include border_color("border_color_white");
  }
  .BottomAxis{
	  position: relative;
	  border-bottom: none!important;
  }
  .information_title{
	  font-size: 18px;
	  padding-top: 1.5vh;
	  @include font_color("font2_color_white");
  }
  .toolManagement{
  	max-height: 19.2vh;
  	overflow-y: auto;
  	overflow-x: hidden;  
  }
  .information_con .mes{
	  height: 5vh;
	  line-height: 5vh;
  }
  .Axis .mes{
	  height: 4vh;
	  line-height: 4vh;
  }
  .Axis2 .mes{
  	  height: 3vh;
  	  line-height: 3vh;
  }
  .information_con .mes span:nth-child(1){
	  font-size: 16px;
	  @include font_color("font7_color_white");
  }
  .information_con .mes span:nth-child(2){
  	  font-size: 15px;
  	  @include font_color("font2_color_white");
  }
  .title_mes{
  	  display: inline-block;
	  margin-left: 20px;
  }
  .title_mes span:nth-child(1){
  	  font-size: 16px;
  	  @include font_color("font7_color_white");
  }
  .title_mes span:nth-child(2){
  	  font-size: 15px;
  	  @include font_color("font2_color_white");
  }
  .echartsCont{
  	  padding-right: 10px;
  }
  .ConDIv{
	  position: relative;
  	  width: 100%;
  	  height:7.8vh;
	  margin-top: 1.5vh;
  	  border-radius: 4px;
  	  // @include background_color("background4_color_white");
	  background-image: url('../../../static/main/Statusresume.png');
	  background-size: 100% 7.8vh;
	  background-repeat: no-repeat;
	  background-position:center;
  }
  .ConDIv span:nth-child(1){
  	  display: inline-block;
  	  position: absolute;
  	  left: 10px;
  	  top: 1.6vh;
  }
  .ConDIv span:nth-child(2){
  	  display: inline-block;
  	  position: absolute;
	  white-space:nowrap;
  	  font-size: 16px;
  	  @include font_color("font7_color_white");
  	  left: 32px;
  	  top: 1.2vh;
  }
  .ConDIv span:nth-child(3){
  	  display: inline-block;
  	  position: absolute;
  	  font-size: 18px;
  	  left: 32px;
  	  top: 4.4vh;
  	  font-weight: 600;
  	  // @include font_color("font2_color_white");
			font-family: 'GemunuLibre-Regular';
  }
  .circle1 {
  	  display: inline-block;
  	  width: 8px;   /* 宽度 */
  	  height: 0.8vh;  /* 高度 */
  	  border-radius: 8px;   /* 边角半径 */
  	  border: 4px solid #38cb89;   /* 边框 */
  }
  .circle2 {
  	  display: inline-block;
  	  width: 8px;   /* 宽度 */
  	  height: 0.8vh;  /* 高度 */
  	  border-radius: 8px;   /* 边角半径 */
  	  border: 4px solid #ff9600;   /* 边框 */
  }
  .circle3 {
  	  display: inline-block;
  	  width: 8px;   /* 宽度 */
  	  height: 0.8vh;  /* 高度 */
  	  border-radius: 8px;   /* 边角半径 */
  	  border: 4px solid #ff9600;   /* 边框 */
  }
  .circle4 {
  	  display: inline-block;
  	  width: 8px;   /* 宽度 */
  	  height: 0.8vh;  /* 高度 */
  	  border-radius: 8px;   /* 边角半径 */
  	  border: 4px solid #ff715b;   /* 边框 */
  }
  .circle5 {
  	  display: inline-block;
  	  width: 8px;   /* 宽度 */
  	  height: 0.8vh;  /* 高度 */
  	  border-radius: 8px;   /* 边角半径 */
  	  border: 4px solid #ff715b;   /* 边框 */
  }
  .circle6 {
  	  display: inline-block;
  	  width: 8px;   /* 宽度 */
  	  height: 0.8vh;  /* 高度 */
  	  border-radius: 8px;   /* 边角半径 */
  	  border: 4px solid #cccccc;   /* 边框 */
  }
  .LineChart{
	position: relative;
  	width: 100%;
  	height: 32vh;
  }
  .FilterCriteria{
    width:100%;
    line-height: 3vh;
    text-align: left;
    font-size:16px;
	padding-top: 1.6vh;
    @include border_color("border_color_white");
  }
  .amchartCont{
	  position: relative;
  	  width: 100%;
  	  height:48vh;
	  padding-top:1.5vh;
  }
  .timeDiv{
  	display:inline-block;
  	width: 60px;
  	text-align: left;
  	@include font_color("font2_color_white");
  	font-size:18px;
  }
}
</style>