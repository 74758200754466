<template>
  <div class="condition EquipmentOverviewQuery">
    <span style="color:#fff !important;margin-right:0.7vw;display:inline-block;" v-if="label">当前日期:</span>
    <button type="button" v-on:click="pre" v-if="showBtn1" style="display:inline-block;color:#fff !important;position:absolute;top:0.4vw;margin-right:1.2vw !important;" aria-label="前一年" class="el-picker-panel__icon-btn el-date-picker__prev-btn el-icon-d-arrow-left"></button>
    <el-date-picker
      :pickerOptions="pickerOptions"
      v-model="value"
      type="date"
      :editable=false
      :clearable=false
      style="width: 10vw;;display:inline-block;"
      @change="selectedDate"
      placeholder="选择日">
    </el-date-picker>
    <button type="button" v-on:click="after" v-if="showBtn2" style="display:inline-block;color:#fff !important;position:absolute;top:0.4vw;margin-left:-0.8vw !important;" aria-label="后一年" class="el-picker-panel__icon-btn el-date-picker__next-btn el-icon-d-arrow-right"></button>
  </div>
</template>

<script>

import {formatDate,addDate} from '@/common/js/date.js'
export default {
  props: {
    day: {
      type: [String,Number],
      default: ''
    },
    label:{
      type: [Boolean],
      default: true
    },
    btn: {
      type: [Boolean],
      default: false
    }
  },
  data () {
    return {
      value:this.day,
      // value:this.global.date,
      showBtn1:false,
      showBtn2:false,
      pickerMinDate: '',
      pickerOptions: {//禁用未来日期
          onPick: ({ maxDate, minDate }) => {
            this.pickerMinDate = minDate.getTime()
            if (maxDate) {
              this.pickerMinDate = ''
            }
          },
          disabledDate: (time) => {
            if (this.pickerMinDate !== '') {
              const day30 = (30 - 1) * 24 * 3600 * 1000
              let maxTime = this.pickerMinDate + day30
              if (maxTime > new Date()) {
                maxTime = new Date()
              }
              return time.getTime() > maxTime
            }
            return time.getTime() > Date.now()
          }
        }
    }
  },
  watch: {
    value(n,o){
      n = formatDate(new Date(n),"yyyy-MM-dd");

      if(this.btn){
        if(new Date(n+" 00:00").getTime() >= new Date(formatDate(new Date(),"yyyy-MM-dd")+" 00:00").getTime()){
          this.showBtn1 = true;
          this.showBtn2 = false
        }else{
          this.showBtn1 = true;
          this.showBtn2 = true;
        }
      }

    }
  },
  mounted () {
    this.$nextTick(() => {
      if(this.btn){
        if(new Date(this.value+" 00:00").getTime() >= new Date(formatDate(new Date(),"yyyy-MM-dd")+" 00:00").getTime()){
          this.showBtn1 = true;
          this.showBtn2 = false
        }else{
          this.showBtn1 = true;
          this.showBtn2 = true;
        }
      }

    })
  },
  beforeDestroy () {

  },
  methods: {
    selectedDate(e){//用户手动改变时触发
      this.$emit("selectedDate",e);
    },
    pre(){
      this.value = addDate(this.value,-1);
      this.$emit("selectedDate",new Date(this.value+""));
    },
    after(){
      this.value = addDate(this.value,1);
      this.$emit("selectedDate",new Date(this.value+""));
    }
  }
}
</script>

<style scoped>
.condition{
  display: inline-block;
}
.EquipmentOverviewQuery >>> .el-date-editor--date .el-input__inner{
  width: 10vw !important;
}
</style>
