<template>
  <div class="amChart_container_n" :id="dom"></div>
</template>

<script>
import {isEmpty,convert,random,milliFormat,milliFormat0} from '@/common/js/base.js'
let chart = [];

export default {
  props: {
    data:Object,
    selectable:Boolean,
    ShowChart:Boolean,
    colors:Object,
    conditions:Boolean,
    isSchema:Boolean,
    DSMTemplateFull:{
      type:Boolean,
      default:false
    },
    spliceScreenSmall:{
      type:false,
      default:false
    },
    rotation:{
      type:Boolean,
      default:false
    }
  },
  data () {
    return {
      dom: "id"+random()
    }
  },
  watch: {
    data(newValue) {
      if(isEmpty(newValue)) return;
      this.init();
    },
    spliceScreenSmall(newVal,oldVal){
      if(isEmpty(newVal)) return;
      this.init();
    }
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.init);
    })
  },
  beforeDestroy () {
    if ( chart[this.dom] && ! chart[this.dom].isDisposed()) {
      setTimeout(()=>{ chart[this.dom].dispose();},1500)
    }
    window.removeEventListener('resize', this.init);
  },
  methods: {
    init(){if(isEmpty(this.data)) return;
      // chart[this.dom]数据转换
      let list=[];
      try{
        for (let j = 0; j < this.data.Dimension.length; j++) {
          let data = {};
          data["Dimension"] = this.data.Dimension[j];
          data["Detail"] = "";
          data["Total"] = 0;
          data["sum"] = 0;
          for (let k = 0; k < this.data.Measure.length; k++) {

            data["Measure"+k] = this.data.Measure[k].data[j];
            data["Total"] += Number(this.data.Measure[k].data[j]);//总量
            data["Detail"] += this.data.Measure[k].name+': '+milliFormat(this.data.Measure[k].data[j])+'\n';
            data["sum"] += Number(this.data.Measure[k].data[j]);//总量
          }
          data["Total"] = '总量'+"："+milliFormat0(data["Total"]);
        //   data["Detail"] += '<div class="detail"><span class="detailIcon" style="background-color:'+color+'"></span>'+this.data.Measure[k].name+': '+this.data.Measure[k].data[j]+''+(!isEmpty(this.data.MeasureUnit)?(!isEmpty(this.data.MeasureUnit.Y1)?this.data.MeasureUnit.Y1.substring(this.data.MeasureUnit.Y1.indexOf('(')):""):"")+'</div>';
        // }
        // data["Total"] = '<div class="total">总量：'+data["Total"]+(!isEmpty(this.data.MeasureUnit)?(!isEmpty(this.data.MeasureUnit.Y1)?this.data.MeasureUnit.Y1.substring(this.data.MeasureUnit.Y1.indexOf('(')):""):"")+'</div>';
          list.push(data);
          if(this.ShowChart===true){
            for (let k = 0; k < this.data.Measure.length; k++) {
              if(this.data.Measure[k].data[j]!=undefined){
                let percent = ((this.data.Measure[k].data[j])/data["sum"]*100).toFixed(2)+"%";
                data["percent"+k] = percent=='NaN%'?"0":percent;
              }
            }
          }
        }
      }catch(e){

      }
      this.$am4core.options.queue = false;
      try{
         chart[this.dom] = this.$am4core.create(this.dom, this.$am4charts.XYChart);
        //  chart[this.dom] = this.$am4core.create(this.dom, this.$am4charts.XYChart3D);
      }catch(e){
      }
      //  chart[this.dom].depth = 5;
      //  chart[this.dom].interpolationDuration = 0;
      let eles = document.querySelectorAll("[aria-labelledby$=-title]");
      for(let index in eles) {
        if(eles[index].style){
          eles[index].style.visibility="hidden";
        }
      };
       chart[this.dom].data = list;
       // Modify chart's colors
       chart[this.dom].colors.list = [
       //   this.$am4core.color("#f0813c"),//尖,橙
       //   this.$am4core.color("#00BFFF"),//峰,蓝
       //   // this.$am4core.color("#cd65fd"),//平,紫
       //   this.$am4core.color("#04984E"),//谷，绿
        this.$am4core.color("#04984E"),//绿
        this.$am4core.color("#00BFFF"),//蓝
        this.$am4core.color("#f0813c"),//橙
        this.$am4core.color("#8068db"),//紫
        this.$am4core.color("#f45c5a"),//粉红
        this.$am4core.color("#e4d456"),//黄
       ];

      if(this.conditions){//有无过滤条件
         chart[this.dom].y = convert(40);
      }else{
         chart[this.dom].y = convert(10);
      }
       chart[this.dom].paddingTop = convert(5);
      if(this.conditions){//有无过滤条件
         chart[this.dom].paddingBottom = convert(35);
      }else{
         chart[this.dom].paddingBottom = convert(5);
      }
       chart[this.dom].paddingLeft = convert(5);

       if (this.isSchema) {//分屏界面
         chart[this.dom].paddingRight = convert(17);
       }else{
         chart[this.dom].paddingRight = convert(30);
       }

       chart[this.dom].legend = new this.$am4charts.Legend();
       chart[this.dom].legend.position = "top";
       chart[this.dom].legend.contentAlign = "right";
       chart[this.dom].legend.labels.template.fill = "#333";
       chart[this.dom].legend.labels.template.fillOpacity = 1;
       chart[this.dom].legend.labels.template.fontSize = convert(12);

      if(this.conditions){//有无过滤条件
         chart[this.dom].legend.itemContainers.template.paddingTop = convert(-30);
      }else{
        //  chart[this.dom].legend.itemContainers.template.paddingTop = convert(-15);
      }
      //  chart[this.dom].legend.itemContainers.template.paddingBottom = convert(10);
      //  chart[this.dom].legend.itemContainers.template.width = convert(35);
      chart[this.dom].legend.itemContainers.template.marginTop = convert(-20);

      var markerTemplate =  chart[this.dom].legend.markers.template;
      markerTemplate.strokeWidth = 0;
      markerTemplate.width = convert(20);
      markerTemplate.height = convert(9);

       chart[this.dom].legend.useDefaultMarker = false;//影响颜色
      var marker = markerTemplate.children.getIndex(0);
      marker.cornerRadius(convert(2), convert(2), convert(2), convert(2));

      // Create axes
      var categoryAxis =  chart[this.dom].xAxes.push(new this.$am4charts.CategoryAxis());
      categoryAxis.dataFields.category = "Dimension";
      // categoryAxis.title.fill = "#ffffff";
      // categoryAxis.title.text = !isEmpty(this.data.MeasureUnit)?(!isEmpty(this.data.MeasureUnit.X)?this.data.MeasureUnit.X.substring(this.data.MeasureUnit.X.indexOf('(')):""):"";
      categoryAxis.renderer.line.strokeOpacity = 1;
      categoryAxis.renderer.line.stroke = "#5da08d";
      categoryAxis.renderer.line.strokeWidth = convert(1);
      categoryAxis.renderer.grid.template.disabled = true;
      categoryAxis.renderer.grid.template.location = 0;
      categoryAxis.renderer.grid.template.strokeOpacity = 1;
      categoryAxis.renderer.grid.template.strokeWidth = convert(0.5);
      categoryAxis.renderer.grid.template.stroke = "#5da08d";
      categoryAxis.renderer.labels.template.fill = "#333";
      // categoryAxis.renderer.labels.template.paddingTop = convert(5);
      // categoryAxis.renderer.labels.template.paddingBottom = convert(10);
        categoryAxis.renderer.minGridDistance = convert(32);//lable margin

      // categoryAxis.startLocation = 0.5;
      // categoryAxis.endLocation = 0.53;

	   categoryAxis.renderer.labels.template.paddingTop = convert(5);
	   categoryAxis.renderer.labels.template.paddingBottom = convert(12);
	   categoryAxis.renderer.labels.template.fontSize = convert(11);
      // if(this.rotation){
      //   categoryAxis.renderer.labels.template.rotation = -45;
      //   categoryAxis.renderer.labels.template.paddingTop = convert(0);
      //   categoryAxis.renderer.labels.template.paddingBottom = convert(7);
      //   categoryAxis.renderer.labels.template.fontSize = convert(12);
      // }else{
      //   categoryAxis.renderer.labels.template.paddingTop = convert(5);
      //   categoryAxis.renderer.labels.template.paddingBottom = convert(12);
      //   categoryAxis.renderer.labels.template.fontSize = convert(12);
      // }

      var  valueAxis =  chart[this.dom].yAxes.push(new this.$am4charts.ValueAxis());
      valueAxis.title.text = !isEmpty(this.data.MeasureUnit)?(!isEmpty(this.data.MeasureUnit.Y1)?this.data.MeasureUnit.Y1:""):"",
      valueAxis.title.fill = "#333333";
      valueAxis.renderer.line.strokeOpacity = 1;
      valueAxis.renderer.line.stroke = "#5da08d";
      valueAxis.renderer.line.strokeWidth = convert(1);
      valueAxis.renderer.grid.template.location = 0;
      valueAxis.min = 0;
      // valueAxis.max = 3400;
      valueAxis.renderer.minWidth = 0;
      valueAxis.renderer.minGridDistance = convert(32);
      valueAxis.renderer.labels.template.fill = "#333333";
      valueAxis.renderer.labels.template.paddingLeft = convert(5);
      valueAxis.renderer.labels.template.paddingRight = convert(5);
	  valueAxis.renderer.labels.template.fontSize = convert(11);
      valueAxis.renderer.grid.template.strokeOpacity = 1;
      valueAxis.renderer.grid.template.strokeWidth = convert(0.5);
      valueAxis.renderer.grid.template.stroke = "#5da08d";

      //动态生成系列
      for (let j = 0; j < this.data.Measure.length; j++) {
        //动态生成系列
        let series = chart[this.dom].series.push(new this.$am4charts.ColumnSeries3D());
        series.dataFields.valueY = "Measure"+j;
        series.dataFields.categoryX = "Dimension";
        series.name = this.data.Measure[j].name;
        let color = this.colors[this.data.Measure[j].name[0]];
        series.columns.template.fill = color;
        if (this.isSchema) {//分屏界面
          // series.columns.template.width = convert(2);
          if (this.DSMTemplateFull) {
            // debugger
            series.columns.template.width = convert(10);//分屏 最下方出现宽屏 放大 柱状图宽度
          }else{
            if (this.spliceScreenSmall) {
              series.columns.template.width = convert(10);//分屏 窄屏放大 柱状图宽度
            }
          }
        }else{
          series.columns.template.width = convert(7);
        }
        series.columns.template.column3D.depth = convert(5);
        // series.columns.template.tooltipHTML = `<div class="tooltipHtml">{Total}{Detail}</div>`;
        // if(j==0){
          // series.tooltipHTML = `<div class="tooltipHtml">{Total}{Detail}</div>`;
          series.columns.template.tooltipText = `{Dimension}\n{Detail}`;
          series.tooltip.label.fontSize = convert(12);
          // Set up tooltip
          // series.adapter.add("tooltipText", (ev)=> {
          //   var text = "[bold]{categoryX}[/]\n{Total} "+(!isEmpty(this.data.MeasureUnit)?(!isEmpty(this.data.MeasureUnit.Y1)?this.data.MeasureUnit.Y1:''):'')+"\n";
          //   // let temp = [];
          //   chart[this.dom].series.each((item,index)=> {
          //     if(this.ShowChart===true){
          //       text+="[" + item.columns.template.fill.hex + "font-size:"+convert(8)+"px !important;]●[/] " + item.name + ": {" + item.dataFields.valueY + "} "+(!isEmpty(this.data.MeasureUnit)?(!isEmpty(this.data.MeasureUnit.Y1)?this.data.MeasureUnit.Y1:''):'')+" , {percent"+index+"}\n";
          //     }else{
          //       text+="[" + item.columns.template.fill.hex + "font-size:"+convert(8)+"px !important;]●[/] " + item.name + ": {" + item.dataFields.valueY + "} "+(!isEmpty(this.data.MeasureUnit)?(!isEmpty(this.data.MeasureUnit.Y1)?this.data.MeasureUnit.Y1:''):'')+"\n";
          //     }
          //   });
          //   // for(let i=temp.length-1;i>=0;i--){
          //   //   text += temp[i]
          //   // }
          //   console.log(text);
          //   return text;
          // });
        // }
        // series.tooltip.background.strokeWidth = convert(1);
        // series.tooltip.background.stroke = 'rgba(7,255,224,0.7)';
        series.tooltip.label.fill = "#fff";
        series.tooltip.getFillFromObject = false;
        series.tooltip.background.strokeOpacity = 0;
        series.tooltip.background.fill = "rgba(48,79,95,0.9)";

        var bullet = series.bullets.push(new this.$am4charts.CircleBullet());
        //默认折线样式
        bullet.circle.radius = convert(0);
        bullet.strokeWidth = 1;//被点击线宽

        if(this.selectable===true){
          series.columns.template.cursorOverStyle = this.$am4core.MouseCursorStyle.pointer;//鼠标形状
        }
        // series.clustered = false;
        series.stacked = true;
        //series.defaultState.transitionDuration = 400;//动画延时

        if(this.selectable===true){
          series.columns.template.events.on("hit", (ev)=> {
            for(let i=0; i<chart[this.dom].series.values.length; i++){//类别数量,循环取消点击状态
              let serie = chart[this.dom].series.values[i]
              for(let k=0; k<serie.columns.values.length; k++){//数据数量
                let value = serie.columns.values[k];
                if(value.dataItem.categoryX === ev.target.dataItem.categoryX){//维度相同
                  //点击状态
                  if(value.column3D){
                    // value.column3D.scale = 1.2;
                    value.column3D.strokeWidth  = convert(0.6);
                    value.column3D.strokeOpacity  = 1;
                    value.column3D.stroke = "#fff";
                  }
                   this.$emit("selectColumn",ev.target.dataItem.categoryX);
                }else{
                  if(value.column3D){
                    value.column3D.strokeWidth  = 0;
                    // value.column3D.scale = 1;
                  }
                }
              }
            }
          }, this);
        }
      }

      if(this.selectable===true){
          // 默认点击效果是倒数第一个有值的x值
          let interval = setInterval(()=>{
            bk:
            for(let k = this.data.Dimension.length-1; k >= 0 ; k--){//数据数量
              for(let i = 0; i < this.data.Measure.length; i++) {//类别数量
                if(this.data.Measure[i].data[k] && this.data.Measure[i].data[k] !=0){
                  try{
                    let dataItems = chart[this.dom].series.values[i].dataItems;
                    let value = dataItems.values[k];
                    if(value.column != undefined && value.column.dataItem.valueY != 0 && value.column.dataItem.valueY != null){
                      value.column.dispatch("hit");
                      window.clearInterval(interval);
                      break bk;
                    }else if(k == 0 && value.column != undefined){
                      value.column.dispatch("hit");
                      window.clearInterval(interval);
                    }
                  }catch(e){
                    window.clearInterval(interval);
                  }
                }
              }
            }
          },30)

          // for(let i = 0; i < chart[this.dom].series.values.length; i++) {//原始方式
          //   let interval = setInterval(()=>{
          //     try{
          //       let dataItems = chart[this.dom].series.values[i].dataItems;
          //       if(dataItems.length>0 && dataItems.values.length>0){
          //           for(let k = dataItems.values.length-1; k >= 0; k--){
          //             let value = dataItems.values[k];
          //               if(value.column != undefined && value.column.dataItem.valueY != 0 && value.column.dataItem.valueY != null){
          //                 value.column.dispatch("hit");
          //                 window.clearInterval(interval);
          //                 break;
          //               }else if(k == 0 && value.column != undefined){
          //                 value.column.dispatch("hit");
          //                 window.clearInterval(interval);
          //               }
          //           }
          //         }
          //     }catch(e){
          //       window.clearInterval(interval);
          //     }
          //   },30)
          // }
      }

      chart[this.dom].cursor = new this.$am4charts.XYCursor();
      chart[this.dom].cursor.maxTooltipDistance = -1;//最多展示一个tooltip -1不重叠
      /* Configure cursor lines */
      chart[this.dom].cursor.xAxis = categoryAxis;
      chart[this.dom].cursor.fullWidthLineX = false;
      chart[this.dom].cursor.lineX.fill = this.$am4core.color("#03d190");
      chart[this.dom].cursor.lineX.fillOpacity = 0.1;

      chart[this.dom].cursor.lineX.stroke = this.$am4core.color("#03d190");
      // chart[this.dom].cursor.lineX.strokeWidth = convert(0.5);
      chart[this.dom].cursor.lineX.strokeOpacity = 0.6;
      chart[this.dom].cursor.lineX.strokeDasharray = "";

      chart[this.dom].cursor.behavior = "";//是否缩放
      chart[this.dom].cursor.lineY.disabled = true;
      valueAxis.cursorTooltipEnabled = false;//禁用垂直值轴上的工具提示
    }
  }
}
</script>
<style scoped>
.amChart_container_n{
	width: 100%;
	height: 100%;
}
</style>
